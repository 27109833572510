import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import publicAxios from "../../utils/publicAxios";
import axiosInstance from "../../utils/axios";
import cookies from "js-cookie";

const lan = cookies.get("i18next") || "en";

const initialState = {
  countries: [],
  cities: [],
  areas: [],
  propertyTypes: [],
  current_country_id: "184",
  current_country_code: "966",
  error: null,
  loading: "idle",
};

export const getCountries = createAsyncThunk("api/countries", async (lang) => {
  let data;
  try {
    const response = await axiosInstance.get(`/getcountries?lang=${lang}`);
    data = await response.data;
    if ((response.status = 200)) {
      return data;
    }
    throw new Error(response.statusText);
  } catch (err) {
    // const error = { message: err.payload.errors[0] };
    const error = err;
    return Promise.reject(error.message ? error.message : data?.message);
  }
});

export const getCities = createAsyncThunk(
  "api/cities",
  async ({ lang, state_id }) => {
    let data;
    try {
      const response = await axiosInstance.get(
        `/getcity?lang=${lang}&country_id=${state_id}`
      );
      data = await response.data;
      if ((response.status = 200)) {
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      // const error = { message: err.payload.errors[0] };
      const error = err;
      return Promise.reject(error.message ? error.message : data?.message);
    }
  }
);

export const getAreas = createAsyncThunk("api/areas", async (city) => {
  let data;
  try {
    const response = await axiosInstance.get(
      `/getarea?lang=${lan}&city_id=${city}`
    );
    data = await response.data;
    if ((response.status = 200)) {
      return data;
    }
    throw new Error(response.statusText);
  } catch (err) {
    // const error = { message: err.payload.errors[0] };
    const error = err;
    return Promise.reject(error.message ? error.message : data?.message);
  }
});

export const getPropertyTypes = createAsyncThunk(
  "api/propertyTypes",
  async (lang) => {
    let data;
    try {
      const response = await axiosInstance.get(
        `/home/get_property_types?lang=${lang}`
      );
      data = await response.data;
      if ((response.status = 200)) {
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      // const error = { message: err.payload.errors[0] };
      const error = err;
      return Promise.reject(error.message ? error.message : data?.message);
    }
  }
);

export const countrySlice = createSlice({
  name: "countries",
  initialState,
  reducers: {
    setCountryCode: (state, action) => {
      state.current_country_code = action?.payload;
    },
    setCountryID: (state, action) => {
      state.current_country_id = action?.payload;
    },
  },
  extraReducers: {
    [getCountries.pending]: (state) => {
      state.error = null;
      state.loading = "loading";
    },
    [getCountries.fulfilled]: (state, action) => {
      const { country } = action.payload;
      state.countries = country;
      state.loading = "idle";
    },
    [getCountries.rejected]: (state, action) => {
      state.error = action.error.message;
    },

    [getCities.pending]: (state) => {
      state.error = null;
      state.loading = "loading";
    },
    [getCities.fulfilled]: (state, action) => {
      const { city } = action.payload;
      state.cities = city;
      state.loading = "idle";
    },
    [getCities.rejected]: (state, action) => {
      state.error = action.error.message;
    },

    [getAreas.pending]: (state) => {
      state.error = null;
      state.loading = "loading";
    },
    [getAreas.fulfilled]: (state, action) => {
      const { area } = action.payload;
      state.areas = area;
      state.loading = "idle";
    },
    [getAreas.rejected]: (state, action) => {
      state.error = action.error.message;
    },

    [getPropertyTypes.pending]: (state) => {
      state.error = null;
      state.loading = "loading";
    },
    [getPropertyTypes.fulfilled]: (state, action) => {
      const { type_list } = action.payload;
      state.propertyTypes = type_list;
      state.loading = "idle";
    },
    [getPropertyTypes.rejected]: (state, action) => {
      state.error = action.error.message;
    },
  },
});

export const { setCountryCode, setCountryID } = countrySlice.actions;

export default countrySlice.reducer;
