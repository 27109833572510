import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosInstance from "../../utils/axios";

import cookies from "js-cookie";

const lang = cookies.get("i18next") || "en";
const initialState = {
  ownProperties: [],

  error: null,
  message: null,
  loading: "idle",
};

export const getOwnProperties = createAsyncThunk(
  "api/ownProperties",
  async ({ body, offer_type }) => {
    try {
      let url = `/user/get_user_properties?lang=${lang}`;
      if (offer_type) {
        url = `/user/get_user_properties?lang=${lang}&offer_type=${offer_type}`;
        body.offer = offer_type;
      }
      const response = await axiosInstance.post(url, body);

      if (response.status === 200) {
        return response.data;
      }

      throw new Error(response.statusText);
    } catch (err) {
      return Promise.reject(err.message || err?.response?.data?.message);
    }
  }
);

export const editOwnProperties = createAsyncThunk(
  "api/editOwnProperties",
  async (body) => {
    let data;

    try {
      const response = await axiosInstance.post(`/user/add_pricing`, body);
      data = await response.data;
      if ((response.status = 200)) {
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      // const error = { message: err.payload.errors[0] };
      const error = err;
      return Promise.reject(error.message ? error.message : data?.message);
    }
  }
);
export const editOwnPropertiesContactInfo = createAsyncThunk(
  "api/editOwnProperties",
  async (body) => {
    let data;

    try {
      const response = await axiosInstance.post(
        `/home/change_property_contact_info`,
        body
      );
      data = await response.data;
      if ((response.status = 200)) {
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      // const error = { message: err.payload.errors[0] };
      const error = err;
      return Promise.reject(error.message ? error.message : data?.message);
    }
  }
);

export const changeOwnPropertiyStatus = createAsyncThunk(
  "api/changeOwnPropertiyStatus",
  async ({ body }) => {
    let data;
    try {
      const response = await axiosInstance.get(
        `/user/share_property?pid=${body?.pid}&offer_type=${body?.offer_type}`
      );
      data = await response.data;
      if ((response.status = 200)) {
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      // const error = { message: err.payload.errors[0] };
      const error = err;
      return Promise.reject(error.message ? error.message : data?.message);
    }
  }
);

export const ownPropertiesSlice = createSlice({
  name: "ownProperties",
  initialState,
  reducers: {},
  extraReducers: {
    [getOwnProperties.pending]: (state) => {
      state.error = null;
      state.loading = "loading";
    },
    [getOwnProperties.fulfilled]: (state, action) => {
      const { properties } = action.payload;
      state.ownProperties = properties;
      state.loading = "idle";
    },
    [getOwnProperties.rejected]: (state, action) => {
      state.error = action.error.message;
    },
  },
});

export const {} = ownPropertiesSlice.actions;

export default ownPropertiesSlice.reducer;
