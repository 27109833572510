import { useEffect } from "react";
import RoutesProvider from "./routes";
import { useTranslation } from "react-i18next";
import cookies from "js-cookie";

import { createTheme, ThemeProvider } from "@mui/material/styles";
import rtlPlugin from "stylis-plugin-rtl";
import { prefixer } from "stylis";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";
import { useDispatch, useSelector } from "react-redux";
import { setLang } from "./data/slices/settingsSlice";
import { Helmet } from "react-helmet";

function App() {
  const dispatch = useDispatch();
  const { direction } = useSelector((state) => state.settings);

  const languages = [
    {
      code: "en",
      name: "English",
      country_code: "gb",
    },
    {
      code: "ar",
      name: "العربية",
      dir: "rtl",
      country_code: "sa",
    },
  ];

  const { t } = useTranslation();
  const currentLanguageCode = cookies.get("i18next") || "en";
  const currentLanguage = languages.find((l) => l.code === currentLanguageCode);

  useEffect(() => {
    document.body.dir = currentLanguage.dir || "ltr";
    dispatch(setLang(currentLanguage.dir || "ltr"));
    // document.title = t("app_title");

    // const metaDescription = document.querySelector('meta[name="description"]');
    // if (metaDescription) {
    //   metaDescription.setAttribute("content", t("app_description"));
    // } else {
    //   const newMetaDescription = document.createElement("meta");
    //   newMetaDescription.name = "description";
    //   newMetaDescription.content = t("app_description");
    //   document.head.appendChild(newMetaDescription);
    // }
  }, [currentLanguage, t]);

  const emptyCache = createCache({
    key: "meaningless-key",
  });

  const cacheRtl = createCache({
    key: "muirtl",
    stylisPlugins: [prefixer, rtlPlugin],
  });

  const theme = createTheme({
    direction: direction,
  });

  return (
    <ThemeProvider theme={theme}>
      <CacheProvider value={direction === "ltr" ? emptyCache : cacheRtl}>
        <Helmet>
          <title>{t("app_title")}</title>
          <meta name="description" content={t("app_description")} />
          <meta property="og:title" content={t("app_title")} />
          <meta property="og:description" content={t("app_description")} />
          <meta property="og:url" content="https://jawlah360.com/" />
          <meta property="og:type" content="website" />
          <meta
            property="og:locale"
            content={currentLanguageCode === "ar" ? "ar_AR" : "en_US"}
          />
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:title" content={t("app_title")} />
          <meta name="twitter:description" content={t("app_description")} />
        </Helmet>
        <RoutesProvider />
      </CacheProvider>
    </ThemeProvider>
  );
}

export default App;
