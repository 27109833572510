import { BrowserRouter, Routes, Route } from "react-router-dom";
import ModalsProvider from "./components/ModalsProvider";
import MainLayout from "./layouts/MainLayout";
import Progress from "./components/Progress/Progress";
import NotFoundView from "./components/NotFound";
import { Navigate } from "react-router-dom";

import { lazy } from "react";
import { Suspense } from "react";
import GuestLayout from "./layouts/GuestLayout/GuestLayout";
import Spinner from "./components/Spinner/Spinner";

const RoutesProvider = () => {
  // const HomeView = lazy(() => import('./views/Home'));
  const AboutView = lazy(() => import("./views/About"));
  const ContactView = lazy(() => import("./views/Contact"));
  const LoginView = lazy(() => import("./views/Login"));
  const RegisterView = lazy(() => import("./views/Register"));
  const ForgotPwdView = lazy(() => import("./views/ForgotPassword"));
  const ResetPwdView = lazy(() => import("./views/ResetPassword"));
  const LandingView = lazy(() => import("./views/Landing"));
  const ProfileUserView = lazy(() => import("./views/ProfileUser"));

  const FavoritesSaleView = lazy(() => import("./views/FavoritesSale"));
  const ForgotPasswordConfirm = lazy(() =>
    import("./views/ForgotPasswordConfirm")
  );
  const PropertyDetails = lazy(() => import("./views/PropertyDetails"));

  const ListSaleView = lazy(() => import("./views/ListSale"));

  const VerifyEmail = lazy(() => import("./views/VerifyEmail"));

  return (
    <BrowserRouter>
      <Suspense fallback={<Spinner />}>
        <Routes>
          {/* Guests Routes */}
          {/* <Route path="/auth" element={<GuestLayout />}>
            <Route index element={<Navigate to="login" />} />
            <Route path="login" element={<LoginView />} />
            <Route path="register" element={<RegisterView />} />
            <Route path="forgot-password" element={<ForgotPwdView />} />
            <Route
              path="forgot-password-confirm"
              element={<ForgotPasswordConfirm />}
            />
          </Route> */}
          {/* <Route path="/" element={<GuestLayout />}>
            <Route path="forgotverification" element={<ResetPwdView />} />
          </Route> */}
          {/* Users Routes */}
          <Route path="/" element={<MainLayout />}>
            {/* <Route index element={<HomeView />} /> */}
            <Route index element={<LandingView />} />
            <Route path="about" element={<AboutView />} />
            <Route path="contact" element={<ContactView />} />
            {/* <Route path="property" element={<PropertyView />} /> */}
            {/* <Route path="profile/user" element={<ProfileUserView />} /> */}
            {/* <Route
              path="management-sale-org"
              element={<ManagementSaleOrgView />}
            /> */}
            {/* <Route
              path="management-rent-org"
              element={<ManagementRentOrgView />}
            /> */}
            {/* <Route path="brokers" element={<BrokersView />} /> */}
            {/* <Route path="favorites" element={<FavoritesSaleView />} /> */}
            <Route path="locations" element={<ListSaleView />} />
            {/* <Route path="verifyproperty" element={<PropertyVerify />} /> */}
            <Route path="verify" element={<VerifyEmail />} />

            <Route path="locations/:id" element={<PropertyDetails />} />
            {/* <Route path="profile-sale" element={<ProfileSaleView />} /> */}
            {/* <Route path={`/:id`} element={<BrokerLayout />} /> */}
          </Route>
          {/* Uncreated Routes */}
          <Route path="*" element={<NotFoundView />} />
        </Routes>
      </Suspense>
      <ModalsProvider />
    </BrowserRouter>
  );
};

export default RoutesProvider;
