import MenuHeader from "../Menu";
import HamburgerMenuTrigger from "../HamburgerMenuTrigger";
import useWindowSize from "../../hooks/useWindowSize";
import { useTranslation } from "react-i18next";
import { logout } from "../../data/slices/authSlice";
import Logo from "../Logo/Logo";
import { NavLink, useNavigate } from "react-router-dom";
import LangBtn from "../LangBtn/LangBtn";
import enAqari from "../../assets/icons/englishLogo.png";
import arAqari from "../../assets/icons/arabicLogo.png";

import iconAqari from "../../assets/icons/aqariIcon.svg";
import cookies from "js-cookie";

const Header = () => {
  const { width } = useWindowSize();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const currentLanguageCode = cookies.get("i18next") || "en";
  const token = localStorage.getItem("aqari_token");

  return (
    <>
      <header className="header">
        {width < 1091 && (
          <div className={"header_responsive"}>
            <div className="left_responsive">
              <HamburgerMenuTrigger />
              <LangBtn />
            </div>
            <img
              className="mobile-logo"
              src={currentLanguageCode === "en" ? enAqari : arAqari}
              alt=""
            />
          </div>
        )}
        {width > 1091 && (
          <>
            <Logo />
            <div className="header__right">
              <NavLink className="item_header link_header" to="/">
                {t("header.home")}
              </NavLink>
              <NavLink className="item_header link_header" to="/locations">
                {t("header.all_properties")}
              </NavLink>
              <NavLink className="item_header link_header" to="/contact">
                {t("header.contact_as")}
              </NavLink>
              {/* {!token && (
                <>
                  <button
                    className="login_header"
                    onClick={() => navigate("/auth/login")}
                  >
                    {t("header.login")}
                  </button>
                </>
              )} */}
              <LangBtn />
              {token && <MenuHeader />}
            </div>
          </>
        )}
      </header>
    </>
  );
};

export default Header;
