import React from "react";
import "./_PdfSection.scss";
import { FaFilePdf } from "react-icons/fa";
import { useTranslation } from "react-i18next";

function PdfSection({ pdf, index }) {
  const { t } = useTranslation();
  const handleNewUrl = (url) => {
    window.open(pdf, "_blank");
  };
  return (
    <div className="pdf" onClick={handleNewUrl}>
      <FaFilePdf color="red" />
      <p className="pdf_title">
        {t("pdf")} {index + 1}
      </p>
    </div>
  );
}

export default PdfSection;
