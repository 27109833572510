import { useEffect } from "react";
import { Select } from "antd";
import { ReactComponent as CountryIcon } from "../../../../assets/icons/landing/country.svg";
import { ReactComponent as CityIcon } from "../../../../assets/icons/landing/city.svg";
import { ReactComponent as AreaIcon } from "../../../../assets/icons/landing/area.svg";
import { ReactComponent as PropertyTypeIcon } from "../../../../assets/icons/landing/property-type.svg";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  getAreas,
  getCities,
  getCountries,
} from "../../../../data/slices/country";
import { getBrokers } from "../../../../data/slices/brokersSlice";
import { handleStateChange } from "../../../../data/slices/filtersSlice";

const Location = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const { countries, cities, areas, propertyTypes } = useSelector(
    (state) => state.countries
  );

  const { direction } = useSelector((state) => state.settings);
  const filters = useSelector((state) => state.filters);

  const { brokers } = useSelector((state) => state.brokers);

  useEffect(() => {
    if (!countries || countries?.length === 0) {
      dispatch(getCountries(direction === "ltr" ? "en" : "ar"));
    }

    // dispatch(getPropertyTypes(direction === 'ltr' ? 'en' : 'ar'));
  }, [direction]);

  const onChangeCountry = (value) => {
    const data = countries.find((obj) => obj?.country_id === value);
    dispatch(
      getCities({
        lang: direction === "ltr" ? "en" : "ar",
        state_id: data?.country_id,
      })
    );
    dispatch(
      handleStateChange({ target: "country_id", value: data?.country_id })
    );
    dispatch(
      handleStateChange({ target: "country_code", value: data?.country_code })
    );
    // dispatch(handleStateChange({ target: 'country', value: data?.country_name }));
  };

  const onChangeCity = (value) => {
    dispatch(getAreas(value));
    dispatch(handleStateChange({ target: "city", value }));
  };

  const onChangeArea = (value) => {
    dispatch(handleStateChange({ target: "area", value }));
  };

  const onChangeBroker = (value) => {
    dispatch(handleStateChange({ target: "sa_id", value }));
  };
  useEffect(() => {
    dispatch(getBrokers());
  }, []);
  //default cities based on the default country located in the slice of countries
  useEffect(() => {
    if (filters?.country_id) {
      dispatch(
        getCities({
          lang: direction === "ltr" ? "en" : "ar",
          state_id: filters?.country_id,
        })
      );
    }
  }, [direction, filters?.country_id]);
  return (
    <div className="location-component">
      {/* {specifications?.country === '1' && ( */}
      <div className="location-select-container">
        <CountryIcon />
        <Select
          showSearch
          optionFilterProp="children"
          onChange={onChangeCountry}
          filterOption={(input, option) =>
            (option?.country_name ?? "")
              .toLowerCase()
              .includes(input.toLowerCase())
          }
          options={countries}
          fieldNames={{ label: "country_name", value: "country_id" }}
          style={{ width: "100%" }}
          autoComplete="none"
          placeholder={t("filters.Select_country")}
          defaultValue={filters?.country_id || null}
          allowClear
        />
      </div>
      {/* )} */}

      {/* {specifications?.city === '1' && ( */}
      <div className="location-select-container">
        <CityIcon />
        <Select
          showSearch
          optionFilterProp="children"
          onChange={onChangeCity}
          filterOption={(input, option) =>
            (option.label ?? "").toLowerCase().includes(input?.toLowerCase())
          }
          options={(direction === "ltr" ? cities?.city : cities?.city)?.map(
            (item) => ({
              label: item?.city_name,
              value: item?.city_id,
            })
          )}
          style={{ width: "100%" }}
          autoComplete="none"
          placeholder={t("filters.Select_city")}
          defaultValue={filters?.city || null}
          allowClear
        />
      </div>
      {/* )} */}

      {/* {specifications?.area === "1" && ( */}
      <div className="location-select-container">
        <AreaIcon />
        <Select
          showSearch
          optionFilterProp="children"
          filterOption={(input, option) =>
            (option.label ?? "").toLowerCase().includes(input?.toLowerCase())
          }
          options={areas?.map((item) => ({
            label: item?.area_name,
            value: item?.area_id,
          }))}
          onChange={onChangeArea}
          style={{ width: "100%" }}
          autoComplete="none"
          placeholder={t("filters.Select_area")}
          defaultValue={filters?.area || null}
          allowClear
        />
      </div>
      {/* )} */}

      {/* //brokers filter  */}
      <div className="location-select-container">
        <PropertyTypeIcon />
        <Select
          showSearch
          optionFilterProp="children"
          options={brokers}
          filterOption={(input, option) =>
            (option?.type_name ?? "")
              .toLowerCase()
              .includes(input.toLowerCase())
          }
          fieldNames={{ label: "name", value: "sa_id" }}
          onChange={onChangeBroker}
          style={{ width: "100%" }}
          autoComplete="none"
          placeholder={t("filters.Property_broker")}
          defaultValue={
            propertyTypes?.find((el) => el.type_name === filters?.type_name)
              ?.type_id || null
          }
          allowClear
        />
      </div>
      {/* )} */}
    </div>
  );
};

export default Location;
