import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  // Location
  country_code: "SA", //when not login pass IP address country code,
  country_id: "184",
  city_id: "",
  type: "",
};

export const filtersSlice = createSlice({
  name: "filters",
  initialState,
  reducers: {
    handleStateChange: (state, action) => {
      const { target, value } = action?.payload;
      if (state[target] === value) {
        state[target] = null;
      } else {
        state[target] = value;
      }
    },
    handleSliderLanding: (state, action) => {
      const { target, value } = action.payload;
      state[target] = value;
    },
    handleStateChangeMultiple: (state, action) => {
      const { target, value } = action?.payload;

      if (state[target]?.includes(value)) {
        state[target] = state[target]
          ?.replace(value, "")
          ?.split(",")
          ?.filter((elm) => elm)
          ?.join(",");
      } else {
        let newArr = state[target]?.split(",")?.filter((elm) => elm);
        newArr?.push(value);
        state[target] = newArr?.join(",");
      }
    },
    setFilterCountry: (state, action) => {
      state.country_code = action.payload;
    },
    setFilterCountryID: (state, action) => {
      state.country_id = action.payload;
    },
  },
});

export const {
  handleStateChange,
  handleStateChangeMultiple,
  handleSliderLanding,
  setFilterCountry,
  setFilterCountryID,
} = filtersSlice.actions;

export default filtersSlice.reducer;
